.colClass {
    &:nth-child(even) {
        padding-right: 0;
    }

    &:nth-child(odd) {
        padding-left: 0;
    }

    @media only screen and (max-width:1200px) {
    }

    &.hover {
        &:hover {
            cursor: pointer;
            filter: drop-shadow(0px 4px 15px rgba(0, 0, 0, 0.08));
        }
    }
}

.cardWrap {
    border-radius: 15px;
    border: 1px solid #E9E9E9;
    background: rgba(255, 255, 255, 0.80);
    padding: 30px 40px;
    width: 100%;
    aspect-ratio: 4 / 1;

    .editButton {
        border-radius: 15px;
        border: 1px solid#E9E9E9;
        background: #F3F6F6;
        padding: 5px 10px;
        color: #171B1F;
        font-size: 13px;
        font-weight: 400;
    }

    @media only screen and (max-width:768px) {
        padding: 15px 20px;
        aspect-ratio: auto;
    }

    .header {
        color: #171B1F;
        font-size: 13px;
        font-style: normal;
        font-weight: 400;
        line-height: 130%;
        opacity: 0.6;
        display: flex;
        justify-content: space-between;
    }

    .value,
    .smallCardValue {
        color: #171B1F;
        font-size: 18px;
        font-style: normal;
        font-weight: 700;
        line-height: 28px;
        overflow: hidden;
    }

    .smallCardValue {
        font-size: 15px;
    }
}


.downloadButton {
    height: 30px;
    padding: 0 20px;
    display: flex;
    align-items: center;
    background-color: #F3F6F6 !important;

    img {
        margin-right: 5px;
    }
}

.fleetId {
    color: #171B1F;
    font-size: 13px;
    font-weight: 400;

    span {
        color: #B0B4BA;
    }
}

.fleetName {
    color: #171B1F;
    font-family: Inter;
    font-size: 16px;
    font-weight: 700;
    margin: 10px 0;
}