.searchInput {
    margin: 0;
    height: 48px;

    min-width: 300px;

}

.searchWrap {
    position: relative;

    @media only screen and (max-width:768px) {
        width: calc(100% - 24px);
        min-width: calc(100% - 24px);
        margin: 10px auto;
}


    .searchIcon {
        position: absolute;
        right: 15px;
        top: 18px;
    }
}