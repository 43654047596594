.ridesPageWrap {


    .header {
        color: #171B1F;
        font-family: Inter;
        font-size: 24px;
        font-style: normal;
        font-weight: 800;
        line-height: normal;
        margin-bottom: 40px;
    }

    .tableHeader {
        color: #B0B4BA;
        font-family: Inter;
        font-size: 18px;
        font-style: normal;
        font-weight: 800;
        line-height: 28px;

        @media only screen and (max-width:768px) {
            padding: 0 12px;
        }

    }

    .tableWrap {
        align-items: center;
        justify-content: space-between;
        margin: 20px 0;

        .filters {
            flex: 2;
            display: flex;
            flex-direction: row-reverse;
            justify-content: flex-start;
            align-items: center;
        }
    }
}

.downloadAllButton {
    height: 48px;
    padding: 0 20px;
}