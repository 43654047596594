.ridesPageWrap {

    .header {
        color: #171B1F;
        font-family: Inter;
        font-size: 24px;
        font-style: normal;
        font-weight: 800;
        line-height: normal;
        margin-bottom: 40px;
        display: flex;
        justify-content: space-between;

        @media only screen and (max-width:768px) {
            flex-direction: column;
            gap: 15px;
            padding: 0 12px;
        }
    }

    .tableHeader {
        olor: #B0B4BA;
        font-family: Inter;
        font-size: 18px;
        font-style: normal;
        font-weight: 800;
        line-height: 28px;


    }

    .tableWrap {
        align-items: center;
        justify-content: space-between;
        margin: 20px 0;

        @media only screen and (max-width:768px) {
            padding: 0 12px;
        }

        .filters {
            align-items: center;

            @media only screen and (max-width:768px) {
                flex: 1;
            }
        }
    }

    .addNewUserButton {
        height: 48px;
        padding: 0 20px;
    }
}