.topRow {
    align-items: center;
    margin-bottom: 30px;
}

.name {
    flex: 5;
    margin: 0 30px;

    color: #171B1F;
    font-size: 24px;
    font-weight: 800;
}

.actionButton {
    height: 30px;
    padding: 0 15px;
    margin-left: 10px;
}

.tableHeader {
    color: #B0B4BA;
    font-family: Inter;
    font-size: 18px;
    font-style: normal;
    font-weight: 800;
    line-height: 28px;
    flex: 0 0 200px;
}

.tableWrap {
    align-items: center;
    flex-wrap: wrap;
    justify-content: space-between;
    margin: 60px 0 20px;

    .filters {
        flex: 1 1 70%;
        align-items: center;
        justify-content: flex-end;
    }
}

.downloadAllButton {
    height: 48px;
    padding: 0 20px;
}

.subHeader {
    color: #B0B4BA;
    font-family: Inter;
    font-size: 18px;
    font-style: normal;
    font-weight: 800;
    line-height: 28px;
}

:global(.rs-toggle-checked .rs-toggle-presentation) {
    background-color: #3BD4AE !important;

    &:hover {
        background-color: #3BD4AE;
    }
}