.footerWrap {
    min-height: 300px;
    border-top: 1px solid #3BD4AE;
    padding: 40px 20px;
    margin-top: 80px;
    display: flex;
    align-items: stretch;
    flex-wrap: wrap;
    gap: 30px 0;
}

.leftSection {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0;
    justify-content: space-between;

    @media only screen and (max-width:768px) {
        padding: 0 20px !important;
    }


    .logo {
        height: 18px;
        margin-bottom: 35px;
    }

    .supportWrap {
        flex: 1;
    }

    .link {
        color: #3E4958;
        font-size: 14px;
        margin-right: 25px;
        &:hover, &:active {
            text-decoration: underline;
        }
    }
}

.rightSection {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.businessLogo {
    max-width: 100%;
}