.content {
    padding: 40px 40px;
    width: 100%;
    overflow: auto;
}

.header {
    color: #171B1F;
    font-family: Inter;
    font-size: 24px;
    font-weight: 800;
    text-align: center;
}

.limitTextField {
    height: 48px;
    padding: 0 50px;
    text-align: center;


}

.numberHandler {
    position: relative;
    max-width: 480px;
    margin: auto;

    .labelClassName {
        text-align: center;
        width: 100%;
        display: block;
        margin: 40px 0 10px;
    }


    .buttonMinus,
    .buttonPlus {
        position: absolute;
        top: 48px;
        height: 30px;
        width: 30px;
        font-size: 20px;
        z-index: 1;
        display: flex;
        align-items: center;
        justify-content: center;

    }

    .buttonMinus {
        left: 10px;
    }

    .buttonPlus {
        right: 10px
    }

}

.setNewLimit,
.userSubmit {
    height: 48px;
    padding: 0 30px;
    margin: 40px auto 0;
    display: block;
}

.userSubmit {
    margin: 40px 0;
}

.label {
    color: #B0B4BA;
    font-family: Inter;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 130%;
    margin: 20px 0 10px;
}

.datePicker {
    margin-bottom: 30px;
    width: 100%;
}

.sendButton,
.downloadFormButton {
    height: 48px;
    min-width: 150px;
    padding: 0 40px;
    margin-top: 30px;
}

.downloadFormButton {
    margin: 30px auto;
    display: block;
}



.buttonWrap {
    display: flex;
    justify-content: space-around;
    margin: 60px 0 20px;

    .button {
        height: 48px;
        width: 160px;
    }
}

.addNewSlot {
    padding: 10px;
    font-size: 13px;
    font-weight: 400 !important;
    background-color: transparent !important;
    display: flex;
    align-items: center;
    gap: 5px;
}

.rsuiteMenu {
    z-index: 999 !important;
}

.classItem,
.dayItem {
    display: flex;
    background-color: #FFF;
    border: 1px solid #E9E9E9;
    margin: 10px;
    height: 48px;
    border-radius: 15px;
    align-items: center;
    padding: 0 20px;
    cursor: pointer;

    .cdescription {
        color: #B0B4BA;
        font-size: 13px;
        margin: 0 5px;
    }

    .clabel {
        color: #171B1F;
        font-size: 14px;
        font-weight: 600;
        margin: 0 5px;
    }
}

.dayItemsHeading {
    margin-top: 30px;
    color: #171B1F;
    font-family: Inter;
    font-size: 14px;
    font-weight: 600;
    height: 48px;
    background-color: #FFF;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 20px;
    border-radius: 15px;
    cursor: pointer;
}

.dayItemsWrap {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    margin: 10px 0;

    .dayItem {
        margin: 0;
    }
}

.timeTableItemWrap {
    display: flex;
    align-items: center;
    gap: 10px;
    font-weight: 700;

    margin: 10px 0;

}

.cloneDescription {
    margin: 10px 0 20px;
    color: #3E4958;
    font-family: Inter;
    font-size: 14px;
    font-weight: 400;
}

.main {
    padding-bottom: 50px;
}


.timeInput {
    display: flex;
    align-items: center;

    span {
        margin: 0 5px;
    }

    input {
        width: 48px;
        height: 48px;
        color: black;
        padding: 5px 10px;
        text-align: center;
    }
}