.inputClass {
    border: 1px solid #E9E9E9;
    background-color: #FFF;
    height: 60px;
    border-radius: 15px;
    margin: 10px 0;
    padding: 0 15px;
    width: 100%;

    &:focus,
    &:hover {
        outline: none;
        border: 1px solid #3BD4AE;
    }
}

.labelClass {}


.inputWrap {
    position: relative;
}

.passwordShowIcon  {
    position: absolute;
    right: 10px;
    top: calc(50% - 12px);
    cursor: pointer;
}