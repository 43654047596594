.button {
    color: #fff;
    font-size: 12px;
    border: 1px solid transparent;
    border-radius: 15px;
    cursor: pointer;
    -webkit-appearance: none;
    -moz-appearance: none;
    transition: background-color .3s;
    white-space: nowrap;
    outline: none;
    font-weight: 800;

    &:disabled {
        border: 1px solid #ececec !important;
        background-color: #ececec !important;
        color: #b2b2b2 !important;
        box-shadow: none;
        cursor: default;
    }

}

.button.variantGreen {
    background-color: #3BD4AE;
    border: 1px solid #00A27F;

    color: #171B1F;
    font-size: 15px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;

    &:hover {
        background-color: darken(#3BD4AE, 10%);
    }

    &:active {
        background-color: darken(#3BD4AE, 20%);
    }

}

.button.variantBlack {
    background-color: #171B1F;
    border: 1px solid #171B1F;

    color: #FAFAFA;
    font-size: 15px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;

    &:hover {
        background-color: lighten(#171B1F, 10%);
    }

    &:active {
        background-color: lighten(#171B1F, 20%);
    }

}

.button.variantWhite {
    background-color: #fff;
    border: 1px solid #E9E9E9;
    padding: 5px 10px;
    color: #171B1F;
    font-size: 15px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;

    &:hover {
        background: #F5FFFD;
        filter: drop-shadow(0px 4px 15px rgba(0, 0, 0, 0.08));
    }

    &:active {
        background: #F5FFFD;

    }

}


.button.variantSecondary {
    background-color: #EEEEEF;
    color: black;

    &:hover {
        background-color: darken(#EEEEEF, 10%);
    }

    &:active {
        background-color: darken(#EEEEEF, 20%);
    }
}