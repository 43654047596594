$columns: 12;
$sm-breakpoint: 576px;
$md-breakpoint: 768px;
$lg-breakpoint: 992px;
$xl-breakpoint: 1200px;
$gutter: 20px;

:global {
  @mixin withGutter($gutter, $prop) {
    @if $gutter != 0 {
      #{$prop}: 0 $gutter;
    }
  }

  .container2 {
    max-width: 1520px;
    margin: 0 auto;
    box-sizing: border-box;
    // @include withGutter($gutter, padding);
  }

  .row2 {
    display: flex;
    flex-wrap: wrap;
    gap: 10px 1%;
    // @include withGutter(-$gutter, margin);
  }

  .col {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
    box-sizing: border-box;
    // @include withGutter($gutter, padding);
    &:first-of-type {
      margin-left: 0;
      // padding-left: 0;
    }
    &:last-of-type {
      margin-right: 0;
      // padding-right: 0;
    }
  }

  @for $width from 1 through $columns {
    .col-#{$width} {
      flex-basis: $width / $columns * 100% - 1%;
      max-width: $width / $columns * 100% - 1%;
    }
  }

  @media (min-width: $sm-breakpoint) {
    @for $width from 1 through $columns {
      .col-sm-#{$width} {
        flex: 0 0 $width / $columns * 100% - 1%;
        max-width: $width / $columns * 100% - 1%;
      }
    }
  }

  @media (min-width: $md-breakpoint) {
    @for $width from 1 through $columns {
      .col-md-#{$width} {
        flex: 0 0 $width / $columns * 100% - 1%;
        max-width: $width / $columns * 100% - 1%;
      }
    }
  }

  @media (min-width: $lg-breakpoint) {
    @for $width from 1 through $columns {
      .col-lg-#{$width} {
        flex: 0 0 $width / $columns * 100% - 1%;
        max-width: $width / $columns * 100% - 1%;
      }
    }
  }

  @media (min-width: $xl-breakpoint) {
    @for $width from 1 through $columns {
      .col-xl-#{$width} {
        flex: 0 0 $width / $columns * 100% - 1%;
        max-width: $width / $columns * 100% - 1%;
      }
    }
  }
}
