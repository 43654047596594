
.textField {
  font-size: 14px;
  padding: 8px 10px;
  border-radius: 10px;
  min-width: 27px;
  border: 1px solid #e9e9e9 !important;
  -webkit-appearance: none !important;
  -moz-appearance: none;
  &.invalid {
    border-color: red !important;
  }
  &:focus:not(:disabled),
  &:active:not(:disabled) {
    outline: none;
  }
}
.inputWrapper {
  display: inline-block;
  .label {
    display: block;
    margin-bottom: 6px;
  }
}
