.navbar {
    height: 100vh;
    background: darken(#F3F6F6, 2%);
    transition: transform 0.3s ease-in-out;
    transform: translateX(-100%);
    z-index: 99999;
    width: 100%;
    max-width: 600px;

    position: absolute;
    top: 80px;
    left: 0px;

    &::after {
        content: "";
        position: absolute;
        top: 0px;
        left: 100%;
        right: 0;
        background-color: rgba(8, 49, 39, 0.6);
        z-index: -1;
        width: 100vw;
        height: 100vh;
        display: none;
        pointer-events: none;
    }

    &.menuActive {
        transform: translateX(0%);

        &::after {
            display: block;
        }

    }


    .itemsWrap {
        display: flex;
        flex-direction: column;
        align-items: center;

        .menuItem {
            height: 50px;
            text-align: center;
            display: flex;
            align-items: center;
            justify-content: center;
            border-bottom: 1px solid #FAFAFA;

            color: #171B1F;
            font-family: Inter;
            font-size: 15px;
            font-weight: 700;
            line-height: normal;
            padding: 0 30px;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            width: 33%;

            img {
                margin-right: 10px;
            }

            .menuItemContent {
                width: 40%;
            }
        }
    }
}