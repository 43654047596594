.actionButtonWrap {
    display: flex;
    align-items: center;

    .button {
        height: 30px;
        color: #171B1F;
        font-family: Inter;
        font-size: 13px;
        font-weight: 400;
        line-height: 130%;
        padding: 0 15px;
        margin: 0 5px;
    }
}

.link {
    color: #00A27F;
    font-family: Inter;
    font-size: 14px;
    font-weight: 700;
    margin-right: 5px;
}

.downloadInvoiceButton {
    padding: 0 5px;
    height: 32px;
    color: #171B1F;
    font-family: Inter;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    display: flex;
    align-items: center;
    justify-content: space-around;
}

.priority {
    color: var(--Red, #E2184A);
    font-size: 12px;
}

.noDataWrap {
    height: 130px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
}

.downloadButtonsWrap {
    display: flex;
    gap: 0 5px;
}