.avatar {
  background: radial-gradient(50% 50% at 50% 50%, #3BD4AE 0%, #000 100%);
  border: 1px solid #3BD4AE;
  color: #FFF;
  text-align: center;
  font-family: Inter;
  font-size: 15px;
  font-weight: 700;
  border-radius: 100%;
  height: 48px;
  width: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  position: relative;
}