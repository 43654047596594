.pickerWrap {
        min-width: 300px;
        margin-right: 25px;

        @media only screen and (max-width:768px) {
                width: calc(100% - 24px);
                min-width: calc(100% - 24px);
                margin: 10px auto;
        }
}



:global {
        .rs-picker-toggle-textbox {
                color: #171B1F !important;
        }

        .rs-picker-toggle-read-only {
                color: #171B1F !important;
        }

        .rs-picker-default {

                &:focus,
                &:active {
                        border-color: red !important;
                }
        }

        .rs-picker-daterange {
                width: 100% !important;

                * {
                        color: #171B1F !important;

                }
        }

        .rs-picker-daterange .rs-picker-toggle.rs-btn-lg .rs-picker-toggle-clean  {
                top: 12px;
        }


        .rs-picker-toggle {
                border-radius: 15px;
                height: 48px;
                padding-top: 12px !important;
                padding-bottom: 12px !important;
                &:hover,
                &:focus {
                    border: 1px solid #00A27F !important;
                }
            }

            .rs-picker-default .rs-picker-toggle.rs-btn-lg .rs-picker-toggle-caret, .rs-picker-default .rs-picker-toggle.rs-btn-lg .rs-picker-toggle-clean {
                top: 12px !important;
            }
    
            .rs-picker-focused {
                border: 1px solid #00A27F !important;
                box-shadow: none !important;
            }
    
            .rs-picker-toggle-active {
                border: 1px solid #00A27F !important;
                box-shadow: none !important;
            }
}