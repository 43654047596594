.modalSectionButtonsWrap {
    gap: 10px;

    @media only screen and (max-width:768px) {
        padding: 0 24px;
        justify-content: space-between;

        .button {
            flex: 1;
        }
    }

    .button {
        height: 48px;
        padding: 0 30px;
    }
}

.modalSection {
    margin: 40px auto;
}

.header {
    color: #B0B4BA;
    font-family: Inter;
    font-size: 18px;
    font-style: normal;
    font-weight: 800;
    line-height: 28px;
    margin-bottom: 20px;

    @media only screen and (max-width:768px) {
        padding: 0 24px;
        width: 100%;
    }
}