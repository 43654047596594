.checkbox {
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100%;
    border: 1px solid #B0B4BA;

    &.selected {
        border: 1px solid #00A27F;
        background-color: #3BD4AE;
    }
}