.breadCrumbWrap {
    margin: 0px auto;

    a {
        color: #00A27F !important;
        font-weight: 700;

        &:hover {
            color: #00A27F !important;
            text-decoration: underline;
        }
    }


    @media only screen and (max-width:768px) {
        margin: 20px auto 0px;
        padding: 0 12px;
    }
}