.topRow {
    align-items: center;
    margin-bottom: 30px;
}

.header {
    color: #171B1F;
    font-family: Inter;
    font-size: 24px;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
    margin-bottom: 40px;
}

.name {
    flex: 5;
    margin: 0 30px;

    color: #171B1F;
    font-size: 24px;
    font-weight: 800;
}

.actionButton {
    height: 30px;
    padding: 0 15px;
    margin-left: 10px;
}

.fetchButton {
    height: 42px;
    padding: 0 20px;
    margin-left: 10px;

    
    @media only screen and (max-width:768px) {
        margin-top: 20px;
    }
}

.tableHeader {
    color: #B0B4BA;
    font-family: Inter;
    font-size: 18px;
    font-style: normal;
    font-weight: 800;
    line-height: 28px;
    margin: 50px 0 20px;
}

.tableWrap {
    align-items: center;
    justify-content: space-between;
    margin: 20px 0 20px;

    .filters {
        align-items: center;
    }
}

.datePicker {
}


:global(.rs-picker-toggle-wrapper) {
    margin: 0 10px;
    width: 200px;

    @media only screen and (max-width:768px) {
        width: 45%;
    }
    * {
        color: #171B1F !important;
    }
}

:global(.rs-picker-toggle-read-only) {
    color: #171B1F !important;
}

:global(.rs-picker-daterange) {
    width: 100% !important;
    height: 48px;

    * {
            color: #171B1F !important;

    }
}

.cardWrap {
    gap: 20px 0;
}