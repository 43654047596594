.dashboard {
    height: 100%;
}


.greetingsContainer {

    margin: 0px 0 50px;
    width: 50%;

    @media (max-width: 768px) {
        width: calc(100% - 40px);
        margin: 50px 20px 50px;
    }

    .header {
        color: var(--Black, #171B1F);
        font-family: Inter;
        font-size: 24px;
        font-style: normal;
        font-weight: 800;
        line-height: normal;
    }

    .desc {
        color: var(--Gray-Dark, #171B1F);
        font-family: Inter;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 136%;
        letter-spacing: 0.1px;
        opacity: 0.6;
    }
}

.cardsWrap {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 20px 1%;
    padding-right: 0;

    @media only screen and (max-width:768px) {
        padding: 0;
    }
    @media only screen and (max-width:576px) {
        flex-direction: column;
        padding: 0;
    }
}


.tableHeaderWrap {
    display: flex;
    justify-content: space-between;
    margin: 20px 0;
    align-items: center;

    @media only screen and (max-width:768px) {
        padding: 0 20px;
    }

    .tableHeader {
        color: #B0B4BA;
        font-family: Inter;
        font-size: 18px;
        font-style: normal;
        font-weight: 800;
        line-height: 28px;
    }

    .showAllButton {
        height: 48px;
        padding: 0 40px;
    }
}

.editLimitButton {
    padding: 0 15px;
}