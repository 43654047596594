.container {
    position: relative;
    padding-bottom: 30px;
}

.table {
    border-collapse: collapse;

    @media only screen and (max-width:768px) {
        margin: 0 12px;
    }

    :global(.rs-table-row-header) {
        background-color: #FAFAFA !important;

        &:hover {
            background-color: #F5FFFD;
        }
    }

    // border: 1px solid #c2c7d4 !important;
    border-radius: 15px 15px;

    .row:nth-child(even) {
        background-color: #F3F6F6;

        div {
            background-color: #F3F6F6;
        }
    }

    .row:not(:first-child):hover {
        div {
            background-color: #f0f3f9;
        }
    }
}

.header {
    div {
        padding: 0 !important;
        border: 0;
        color: #3E4958 !important;

        div {
            display: flex;
            align-items: center;
            border: 0 !important;
            background-color: #FAFAFA;
            padding-left: 15px !important;
        }
    }

    div:not(:last-child) {
        border-right: 1px solid #c2c7d4 !important;
    }
}

.cell {
    padding: 0 !important;
    color: #171B1F;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;

    &>div {
        padding-left: 15px;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
}

.row {
    border: 1px solid rgba(20, 32, 65, 0.15);
    background-color: var(--rs-bg-card);
}

.row:hover {
    cursor: pointer;
}


.paginationWrap {
    margin: 20px 0;

    * {
        color: #00A27F !important;
    }

    @media only screen and (max-width:768px) {
        padding: 0 12px;
    }


    :global {

        .rs-picker-select-menu-item {
            color: #00A27F !important;
        }

        .rs-picker-select-menu-item-active {
            color: #00A27F !important;
        }

        .rs-pagination-btn-active {
            border: 1px solid #00A27F !important;
            color: #00A27F !important;
            font-weight: 700 !important;
        }

        .rs-picker-select-menu-item-focus {
            color: #00A27F !important;
        }

        .rs-picker-default .rs-picker-toggle {

            &:hover,
            &:focus {
                border: 1px solid #00A27F !important;
            }
        }

        .rs-picker-toggle {

            &:hover,
            &:focus {
                border: 1px solid #00A27F !important;
            }
        }

        .rs-picker-focused {
            border: 1px solid #00A27F !important;
            box-shadow: none !important;
        }

        .rs-picker-toggle-active {
            border: 1px solid #00A27F !important;
            box-shadow: none !important;
        }
    }

}


// .rs-picker-select-menu-item
// rs-picker-select-menu-item-active

.table {

    a {
        color: #00A27F !important;
        font-weight: 700;

        &:hover {
            color: #00A27F !important;
            text-decoration: underline;
        }
    }
}