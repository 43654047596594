.loginPage {
    display: flex;
    // flex-wrap: wrap;
    width: 100%;
    height: 100%;
    background-color: #F3F6F6;

    .loginLeft,
    .loginRight {
        padding: 150px;
        background-size: cover;
        background-repeat: no-repeat !important;
        display: flex;
        justify-content: center;

        @media only screen and (max-width:768px) {
            padding: 15px 25px;
        }
    }

    .loginLeft {

        background-size: cover;
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        background-position: bottom left !important;
    }

    .loginRight {
        border-left: 1px solid #E9E9E9;
        background-size: cover;
        background-position: bottom right !important;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
    }

    .loginForm {
        padding: 30px 0;
        width: 100%;
        max-width: 480px;
    }

    .submitButton {
        height: 60px;
        width: 160px;
        margin-top: 20px;
    }


}


.description {
    color: #171B1F;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 136%;
    letter-spacing: 0.1px;
    margin: 20px 0 20px;
    text-align: left;
    max-width: 450px;
}

.header {
    color: #171B1F;
    font-size: 18px;
    font-style: normal;
    font-weight: 800;
    line-height: 28px;
    margin-bottom: 10px;
    text-align: left;
}

.storesWrap {

    margin: 30px 0 !important;



    @media only screen and (max-width:768px) {
        flex-direction: column;
        align-items: center;
        gap: 20px;
    }

    img {
        height: 36px;
        width: 108px;
        margin-right: 10px;
    }
}

.wrongCredentials {
    color: #e2184a;
    font-weight: 700;
}

.forgotPasswordLink {
    display: block;
    margin-top: 20px;
    color: #171B1F;

    &:hover {
        color: #171B1F;
        text-decoration: underline;
    }
}