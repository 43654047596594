.mainCard {
    background-image: url("../../static/images/main-card-background.png");
    border-radius: 15px;
    border: 1px solid #171B1F;
    background-color: #060707;
    background-size: cover;
    box-shadow: 0px 0px 10px 0px rgba(17, 17, 17, 0.15);
    aspect-ratio: 12 / 7;
    padding: 30px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;


    .mainInfoText {
        color: #FAFAFA;
        font-size: 18px;
        font-weight: 700;
        line-height: 28px;
        letter-spacing: 3px;
    }

    .companyName {
        color: #3BD4AE;
        font-size: 13px;
        font-weight: 400;
        line-height: 130%;
    }

    .label {
        color: #FAFAFA;
        font-size: 13px;
        font-weight: 400;
        line-height: 130%;
        opacity: 0.6;
        margin-right: 5px;
    }

    .addCreditButton {
        height: 40px;
        padding: 0 20px;
        width: 50%;
        max-width: 180px;
    }
}


.noPadding {
    padding-left: 0;
    @media only screen and (max-width:768px) {
        padding: 20px;
    }
}