.headerWrap {
    width: 100%;
    background-color: #171B1F;
    background-image: url("../static/images/header-background.png");
    background-repeat: no-repeat;
    height: 80px;
    position: sticky;
    top: 0;
    z-index: 98;
    padding: 0 20px;



    .header {
        height: 100%;
        display: flex;
        align-items: center;


        .burger {
            margin-right: 10px;
            cursor: pointer;
        }

        .companyName {
            color: #FAFAFA;
            font-size: 15px;
            font-style: normal;
            font-weight: 400;
            line-height: 136%;
            letter-spacing: 0.1px;

            margin-left: 20px;
            padding: 0 20px;
            border-left: 1px solid #FAFAFA;
            flex: 1;
        }
    }
}

.signOut {
    padding: 5px 20px;
    font-weight: 700;
    cursor: pointer;

    &:hover {
        background: #F5FFFD;
    }
}


.profile {
    border: 1px solid #3BD4AE;
    border-radius: 100%;
    height: 48px;
    width: 48px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.initials {
    color: white;
    font-size: 15px;
    font-weight: 700;
}

.popoverButtons {}


:global {
    .tippy-box {
        border-radius: 15px !important;
        padding: 0px;
    }
}